import { render, staticRenderFns } from "./FDivisionTable.vue?vue&type=template&id=56303ed9&scoped=true"
import script from "./FDivisionTable.vue?vue&type=script&lang=js"
export * from "./FDivisionTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56303ed9",
  null
  
)

export default component.exports