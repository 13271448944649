<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>

    </v-card-title>
    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fPegawaisFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>


      <template v-slot:[`item.phone`]="{ item }">
        <div class="fill-height">
          <div v-if="item.phone >1">
            <v-icon x-small>mdi-phone</v-icon>
            +{{item.countryCode}}-{{item.phone }}
          </div>
          <div class="caption" v-if="item.email !== '' ">
            <v-icon x-small>mdi-email</v-icon>
            {{ item.email }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <div class="caption">
            {{ lookupFDivision(item.fdivisionBean) }}
          </div>
          <div class="subtitle-2">
            <v-btn
              icon
              @click="showOrgChartDialog(item)"
            >
              <v-chip small>
                {{ lookupESalesType(item.salesType) }}
              </v-chip>
            </v-btn>
          </div>
          <div v-if="item.childOf" >
            <span class="caption">dari</span> {{ lookupFSalesman(item.childOf) }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>


      <template v-slot:[`item.avatar`]="{ item}">
        <v-avatar
            class="ma-1"
            size="72px"
        >
          <v-img
              :lazy-src="lookupImageUrlLazy(item)"
              :src="lookupImageUrl(item)"
              alt="avatar"
          >
          </v-img>
        </v-avatar>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-1">
          {{item.spname}}
        </div>
        <div class="subtitle-2 font-weight-light" v-if="item.spcode">
          NIP *{{item.spcode}}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>
<!--    @page-count="pageCount = $event"-->

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>

    <FPegawaiDialog
        :formMode.sync="formMode"
        :itemsFSalesman="itemsFSalesman"
        ref="refFormDialog"
            @eventFromFormDialogNew="saveDataNew"
            @eventFromFormDialogEdit="saveDataEdit"
    ></FPegawaiDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FPegawaiService from '../../../services/apiservices/f-pegawai-service';
import FDivisionService from "../../../services/apiservices/f-division-service";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";

import FPegawaiDialog from "./FPegawaiDialog";
import FormMode from "../../../models/form-mode";
import FPegawai from '../../../models/f-pegawai'
import FileService from "../../../services/apiservices/file-service";
import  {ESalesTypes} from "../../../models/e-sales-type";

export default {
  components: { FPegawaiDialog, DeleteConfirmDialog},
  data () {
    return {
      title: 'STAFF-SALESMAN',
      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'avatar',
        },
        { text: 'Pegawai/Salesman', value: 'description', width:"35%" },
        { text: 'Phone', value: 'phone', width: "35%" },
        { text: 'Branch/Distributor', value: 'fdivisionBean' },
        { text: 'Aktif', value: 'statusActive' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fPegawais:[
        new FPegawai(0, '','')
      ],
      itemsFDivision: [
        { id: 0, kode1: '', description: '' },
      ],
      itemsFSalesman:[],
      itemsESalesType: ESalesTypes,

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFPegawai()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFPegawai()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fPegawaisFiltered(){
      return this.fPegawais
    }
  },
  methods: {
    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFPegawai()
      }
    },
    fetchParent(){
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }
      FPegawaiService.getAllFPegawai().then(
          response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFSalesman = response.data
          },
          error =>{
            console.log(error)
          }
      )

    },
    fetchFPegawai() {

      FPegawaiService.getAllFPegawaiContaining(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
          response => {
            // console.log(response.data.items)
            const { items, totalPages} = response.data
            this.fPegawais = items
            this.totalPaginationPages = totalPages
            console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
            if (error.response.status===401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )
    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogNew() {

      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    showOrgChartDialog(item){
      this.itemSelectedIndex = this.fPegawaisFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      this.$refs.refOrgChartDialog.showDialog(this.itemSelectedIndex, itemModified)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fPegawais.push(this.itemSelected)
      // this.fPegawais = [itemFromRest].concat(this.fPegawais)
      // this.fPegawais.unshift(itemFromRest)
      this.fetchFPegawai()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fPegawaisFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          Object.assign(this.fPegawais[this.itemSelectedIndex], this.itemSelected)
        }catch (e) {
            this.fetchFPegawai()
            e.toString()
        }
        this.closeDialog()
    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fPegawaisFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fPegawais[this.itemSelectedIndex]
      FPegawaiService.deleteFSalesman(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fPegawais.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FPegawaiService.deleteAllFSalesman(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.fPegawaisFiltered.indexOf(items[i])
                      this.fPegawais.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    console.log("Undeleted Items: " + response.data + " = " + items[i].id)
                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FPegawaiService.deleteAllFPegawais()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FPegawai())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },

    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)
      if (str.length>0){
        return `${str[0].spname} *${str[0].spcode}`
      }else {
        return '-'
      }
    },
    lookupESalesType (salesType) {
      const str = this.itemsESalesType.filter(x => x.id===salesType)
      if (str.length>0){
        return `${str[0].description} `
      }else {
        return '-'
      }
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },

    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchFPegawai()
      this.fetchParent()

    }
  }

}
</script>

<style scoped>

</style>