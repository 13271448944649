import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FPegawaiService {
    getApiUrl() {
        return API_URL
    }

    getAllFSalesman(){
        return axios.get(API_URL + `getAllFSalesman`, { headers: authHeader() });
    }
    getAllFPegawai(){
        return axios.get(API_URL + `getAllFSalesman`, { headers: authHeader() });
    }
    getAllFSalesmanContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFSalesman`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFSalesmanContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFPegawaiContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFSalesman`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFSalesmanContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFSalesmanByDivision(fdivisionBean){
        return axios.get(API_URL + `getAllFSalesmanByDivision/${fdivisionBean}`, { headers: authHeader() });
    }

    getFSalesmanById(id){
        return axios.get(API_URL + `getFSalesmanById/${id}`, { headers: authHeader() });
    }
    getFPegawaiById(id){
        return axios.get(API_URL + `getFSalesmanById/${id}`, { headers: authHeader() });
    }
    updateFSalesman(item){
        return axios.put(API_URL + `updateFSalesman/${item.id}`, item, {headers: authHeader()})
    }
    updateFPegawai(item){
        return axios.put(API_URL + `updateFSalesman/${item.id}`, item, {headers: authHeader()})
    }
    createFSalesman(item){
        return axios.post(API_URL + `createFSalesman`, item, {headers: authHeader()})
    }
    createFPegawai(item){
        return axios.post(API_URL + `createFSalesman`, item, {headers: authHeader()})
    }
    deleteFSalesman(id){
        return axios.delete(API_URL + `deleteFSalesman/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFSalesman(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFSalesman`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFSalesman`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FPegawaiService()